import './astrid.scss';

import React from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../helpers/icon';
import astridIcons from './astridIcons';
import tt from '../../helpers/translation';

import paw from '../../images/Maps/pa.jpg';
import FL from '../../images/Maps/fl.jpg';
import KA from '../../images/Maps/fl-infantil.jpg';
import AP from '../../images/Maps/ca.jpg';
import Halloween from '../../images/Maps/halloween_map.png'

import polynesia from '../../images/Maps/poly.png';
import mexico from '../../images/Maps/mex.jpg';
import farWest from '../../images/Maps/fw.jpg';
import mediterrania from '../../images/Maps/medit.jpg';
import sesamoAventura from '../../images/Maps/sesamo.jpg';
import china from '../../images/Maps/ch.jpg';

import hcaribe from '../../images/Maps/hca.jpg';
import hcolorado from '../../images/Maps/hcol.jpg';
import hpaso from '../../images/Maps/hep.jpg';
import hRiver from '../../images/Maps/hgr.jpg';
import hlucy from '../../images/Maps/hml.jpg';
import hpa from '../../images/Maps/hpa.jpg';

import convent from '../../images/Maps/convent.jpg';

import distparques from '../../images/Maps/paw.jpg';

class Astrid extends React.Component {
  state = {};

  componentWillReceiveProps() {
    this.setState({
      showModal: this.props.modal,
    });
  }

  useMap = () => {
    let imageMap;
    if (this.props.pageContext.pageName === 'ficha_mundos') {
      switch (this.props.pageContext.mundo) {
        case 'polynesia':
          imageMap = polynesia;
          break;
        case 'mexico':
          imageMap = mexico;
          break;
        case 'mediterrania':
          imageMap = mediterrania;
          break;
        case 'sesamo-aventura':
          imageMap = sesamoAventura;
          break;
        case 'far-west':
          imageMap = farWest;
          break;
        case 'china':
          imageMap = china;
          break;
        default:
          imageMap = undefined;
          break;
      }
    }
    if (this.props.pageContext.pageName === 'kids_area') {
      imageMap = KA;
    }

    if (this.props.pageContext.pageName === 'ficha_parques') {
      switch (this.props.pageContext.park_name) {
        case 'FerrariLand':
          imageMap = FL;
          break;
        case 'PortAventura':
          imageMap = paw;
          break;
        case 'AcuaticPark':
          imageMap = AP;
          break;
        case 'Halloween':
          imageMap = Halloween;
          break;
        default:
          imageMap = paw;
          break;
      }
    } else if (this.props.pageContext.pageName === 'ficha_hoteles') {
      switch (this.props.pageContext.hotel_name) {
        case 'gold-river':
          imageMap = hRiver;
          break;
        case 'port-aventura':
          imageMap = hpa;
          break;
        case 'mansion-de-lucy':
          imageMap = hlucy;
          break;
        case 'caribe':
          imageMap = hcaribe;
          break;
        case 'el-paso':
          imageMap = hpaso;
          break;
        case 'colorado-creek':
          imageMap = hcolorado;
          break;
        default:
          imageMap = undefined;
          break;
      }
    } else if (
      this.props.pageContext.pageName === 'navidad' ||
      this.props.pageContext.pageName === 'shows_navidad' ||
      this.props.pageContext.pageName === 'promotion_navidad'
    ) {
      imageMap = paw;
    }

    if (this.props.pageContext.pageName === 'ficha_hoteles') {
      switch (this.props.pageContext.hotel_name) {
        case 'gold-river':
          imageMap = hRiver;
          break;
        case 'port-aventura':
          imageMap = hpa;
          break;
        case 'mansion-de-lucy':
          imageMap = hlucy;
          break;
        case 'caribe':
          imageMap = hcaribe;
          break;
        case 'el-paso':
          imageMap = hpaso;
          break;
        case 'colorado-creek':
          imageMap = hcolorado;
          break;
        default:
          imageMap = undefined;
          break;
      }
    }
    if (
      this.props.pageContext.pageName === 'shows_halloween' ||
      this.props.pageContext.pageName === 'promotion_halloween'
    ) {
      // switch (this.props.pageContext.locale) {
      //   case 'es':
      //     imageMap = HA_ES;
      //     break;
      //   case 'fr':
      //     imageMap = HA_FR;
      //     break;
      //   case 'ca':
      //     imageMap = HA_CA;
      //     break;
      //   case 'en':
      //     imageMap = HA_EN;
      //     break;
      //   default:
      //     imageMap = HA_EN;
      // }
      imageMap = paw;
    }
    if (this.props.pageContext.pageName === 'distributiva_parques') {
      imageMap = distparques;
    }
    if (this.props.pageContext.pageName === 'business') {
      imageMap = convent;
    }
    return imageMap;
  };

  handleChange = () => {
    this.props.handleMap();
  };

  render() {
    const { pageContext } = this.props;
    return (
      <div className="astrid-container">
        {this.props.modal && (
          <div className="modal is-active">
            <div className="modal-background" tabIndex="0" onClick={this.handleChange} />
            <div className="modal-content">
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={this.handleChange}
              />
              <div className="map-download">
                <Icon
                  icon="get_app"
                  width="20"
                  height="20"
                  class="icon-arrow"
                  color="white"
                  iconSet={astridIcons}
                />
                <a
                  href={this.useMap()}
                  download={`${this.props.pageContext.mundo ||
                    this.props.pageContext.hotel_name ||
                    this.props.pageContext.park_name ||
                    this.props.pageContext.pageName}.png`}
                >
                  {tt('Descargar Mapa', this.props.intl.locale)}
                </a>
              </div>
              <img src={this.useMap()} className="image-final" alt="Mapa" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default injectIntl(Astrid);
