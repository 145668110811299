
const astridIcons = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M952.902 643.672v191.439h-854.375v-191.439h-65.721v223.478c0 17.685 14.721 32.036 32.859 32.036h920.098c18.138 0 32.861-14.352 32.861-32.036v-223.478h-65.721zM746.207 427.915c-12.946-12.623-33.914-12.623-46.925 0l-140.709 160.123v-478.324h-65.72v478.324l-140.775-160.123c-12.948-12.623-33.914-12.623-46.925 0-12.666 12.349-12.941 32.238-0.826 44.915 0.269 0.281 0.544 0.559 0.826 0.834l197.033 224.008c6.506 6.343 14.982 9.484 23.528 9.484 8.544 0 17.020-3.141 23.526-9.42l196.967-224.072c12.948-12.623 12.948-33.126 0-45.749z',
        ],
        attrs: [],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'download',
        ],
        defaultCode: 59394,
        grid: 0,
      },
      attrs: [],
      properties: {
        id: 28,
        order: 8,
        ligatures: '',
        prevSize: 32,
        code: 59394,
        name: 'download',
      },
      setIdx: 0,
      setId: 4,
      iconIdx: 28,
    },
    {
      icon: {
        paths: [
          'M214 789.333h596v86h-596v-86zM810 405.333l-298 298-298-298h170v-256h256v256h170z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'get_app',
        ],
        defaultCode: 58008,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 7,
        order: 1062,
        ligatures: '',
        prevSize: 32,
        code: 58008,
        name: 'get_app',
      },
      setIdx: 5,
      setId: 2,
      iconIdx: 6,
    },
  ],
};

export default astridIcons;
